@import (reference) "../../styles/main";

.Customers {
  max-width: 100%;
  &.CustomersMargin {
    margin-top: 3rem;

    @media @mobile {
      margin-top: 3.75rem;
    }
  }
}

.CustomersHeader {
  .text-paragraph();
  color: @color-violet-200;
  display: inline-flex;
  @media @mobile {
    display: none;
  }
}

@length: 18;
@slideWidth: 12rem;
@slideHeight: 5rem;

@keyframes trackslide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(@slideWidth * @length * -0.5));
  }
}

.CustomersLogosSlider {
  width: 100%;
  max-width: 100%;
  margin: auto;
  overflow: hidden;
  position: relative;

  .CustomersLogosTrack {
    animation: trackslide 60s linear infinite;
    display: flex;
    align-items: center;
    width: @slideWidth * @length;
  }

  .CustomersLogosSlide {
    height: @slideHeight;
    width: @slideWidth;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .BrightLogo {
    filter: brightness(100);
  }
}
