@import (reference) "../../styles/main";

.Customers {
  @media @tablet {
    padding-top: @main-padding-top !important;
    padding-bottom: @main-padding-bottom !important;
  }

  @media @mobile {
    padding-top: @main-padding-top-mobile !important;
    padding-bottom: @main-padding-bottom-mobile !important;
  }
}

.ReadyToGetStart {
  height: auto;

  @media (min-width: 1025px) {
    height: 222px;
  }

  @media @mobile {
    height: auto;
  }
}
.ExternalDataPartner {
  font-size: 20px !important;

  @media (min-width: 1025px) {
    width: 644px;
    height: 71px;
  }

  @media @tablet {
    width: auto;
    height: auto;
  }

  @media @mobile {
    width: auto;
    height: auto;
  }
}

.ExternalDataDeployment {
  height: 524px;
  background: linear-gradient(180deg, #F3F2FF 0%, #FFFFFF 100%);
}

.ClassSectionWrapper {
  flex-direction: column !important;

  @media (min-width: 1025px) {
    flex-direction: row !important;
  }

  @media @tablet {
    flex-direction: column !important;
  }

  @media @mobile {
    flex-direction: column !important;
  }
}

.ExternalDataDeploymentText {
  margin-top: 10%;
  height: 100%;
  justify-content: flex-start !important;
  flex: initial !important;
}

.ExternalDataDeploymentMap {
  width: 450px;
  height: 200px;
  margin-left: 20%;

  @media (min-width: 1025px) {
    width: 738px;
    height: 467px;
    margin-left: 0;
  }

  @media @tablet {
    width: 500px;
    height: 200px;
    margin-left: 0;
  }

  @media @mobile {
    width: 350px;
    height: 150px;
    margin-left: 0;
  }
}

.ParagraphExternalDataDeployment {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0;
  text-align: left;

  @media (min-width: 1410px) {
    width: 601px;
    height: 135px;
    margin-top: 5% !important;
  }

  @media @tablet {
    width: auto;
    height: auto;
    margin-top: 0 !important;
  }

  @media @mobile {
    width: 350px;
    height: 150px;
    margin-top: 0 !important;
  }
}

.BigNumbers {
  @media (min-width: 1025px)  {
    height: 500px;
  }

  @media @mobile {
    height: auto;
  }

  @media @tablet {
    height: auto;
  }

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 14rem;
    background-color: @color-blue-200;

    @media @tablet {
      height: 100%;
      background-color: @color-blue-100;
    }
  }

  p[class*="Section_SectionSubtitle"] {
    font-size: 1.15rem;
  }
}

.PlatformOverviewImage {
  flex-basis: 28rem !important;
  @media @tablet-landscape {
    flex-basis: auto !important;
  }
}

.ResourcesCol {
  align-self: flex-start;
  > h2 {
    margin-bottom: 3rem !important;

    @media @tablet {
      margin-bottom: 2rem !important;
    }
  }
}
