@import (reference) "./colors";
@import (reference) "./breakpoints";

@font-family-body: "Open Sans", Arial, sans-serif;
@font-family-headings: "Montserrat", Verdana, sans-serif;
@font-family-mono: "Source Code Pro", Menlo, Monaco, Consolas, "Courier New";

@button-font-size: 1rem;
@button-font-weight: 500;
@button-font-family: @font-family-headings;

@text-heading-1-font-size: 3.125rem;
@text-heading-1-line-height: 1.2;
@text-heading-1-font-family: @font-family-headings;
@text-heading-1-font-weight: 700;
@text-heading-1-font-size-mobile: 3rem;
@text-heading-1-color: @color-coolGray-900;
@text-heading-1-margin-top: 0;
@text-heading-1-margin-bottom: 0;

@text-heading-2-font-size: 2.25rem;
@text-heading-2-line-height: 1.2;
@text-heading-2-font-family: @font-family-headings;
@text-heading-2-font-weight: 600;
@text-heading-2-font-size-mobile: 2rem;
@text-heading-2-margin-top: 0;
@text-heading-2-margin-bottom: 0;
@text-heading-2-color: @color-coolGray-900;

@text-heading-3-font-size: 1.25rem;
@text-heading-3-line-height: 1.4;
@text-heading-3-font-family: @font-family-headings;
@text-heading-3-font-weight: 600;
@text-heading-3-font-size-mobile: 1.25rem;
@text-heading-3-margin-top: 1rem;
@text-heading-3-margin-bottom: 1.5rem;
@text-heading-3-color: @color-coolGray-900;

@text-heading-4-font-size: 1rem;
@text-heading-4-line-height: 1.4;
@text-heading-4-font-family: @font-family-headings;
@text-heading-4-font-weight: 500;
@text-heading-4-font-size-mobile: 1rem;
@text-heading-4-margin-top: 0.75rem;
@text-heading-4-margin-bottom: 1rem;
@text-heading-4-color: @color-coolGray-900;

@text-subheading-1-font-size: 1.25rem;
@text-subheading-1-line-height: 1.4;
@text-subheading-1-font-family: @font-family-body;
@text-subheading-1-font-weight: 500;
@text-subheading-1-font-size-mobile: 1.25rem;
@text-subheading-1-margin-top: 1rem;
@text-subheading-1-margin-bottom: 2rem;
@text-subheading-1-color: @color-violet-600;

@text-subheading-2-font-size: 1.25rem;
@text-subheading-2-line-height: 1.4;
@text-subheading-2-font-family: @font-family-body;
@text-subheading-2-font-weight: 500;
@text-subheading-2-font-size-mobile: 1.25rem;
@text-subheading-2-margin-top: 2rem;
@text-subheading-2-margin-bottom: 2.5rem;
@text-subheading-2-color: @color-violet-600;

@text-paragraph-font-size: 0.9375rem;
@text-paragraph-line-height: 1.5;
@text-paragraph-font-weight: 500;
@text-paragraph-font-family: @font-family-body;
@text-paragraph-font-size-mobile: 0.9375rem;
@text-paragraph-margin-top: 0.25rem;
@text-paragraph-margin-bottom: 0.75rem;
@text-paragraph-color: @color-violet-700;

.button-text {
  font-size: @button-font-size;
  font-weight: @button-font-weight;
  font-family: @button-font-family;
}
.text-heading-1 {
  font-size: @text-heading-1-font-size;
  line-height: @text-heading-1-line-height;
  font-family: @text-heading-1-font-family;
  font-weight: @text-heading-1-font-weight;
  font-weight: @text-heading-1-font-weight;
  color: @text-heading-1-color;
  margin-top: @text-heading-1-margin-top;
  margin-bottom: @text-heading-1-margin-bottom;

  @media @mobile {
    font-size: @text-heading-1-font-size-mobile;
  }
}
.text-subheading-1 {
  font-size: @text-subheading-1-font-size;
  line-height: @text-subheading-1-line-height;
  font-family: @text-subheading-1-font-family;
  font-weight: @text-subheading-1-font-weight;
  font-weight: @text-subheading-1-font-weight;
  color: @text-subheading-1-color;
  margin-top: @text-subheading-1-margin-top;
  margin-bottom: @text-subheading-1-margin-bottom;

  @media @mobile {
    font-size: @text-subheading-1-font-size-mobile;
  }
}
.text-subheading-2 {
  font-size: @text-subheading-2-font-size;
  line-height: @text-subheading-2-line-height;
  font-family: @text-subheading-2-font-family;
  font-weight: @text-subheading-2-font-weight;
  font-weight: @text-subheading-2-font-weight;
  color: @text-subheading-2-color;
  margin-top: @text-subheading-2-margin-top;
  margin-bottom: @text-subheading-2-margin-bottom;

  @media @mobile {
    font-size: @text-subheading-2-font-size-mobile;
  }
}

.text-heading-2 {
  font-size: @text-heading-2-font-size;
  line-height: @text-heading-2-line-height;
  font-family: @text-heading-2-font-family;
  font-weight: @text-heading-2-font-weight;
  font-weight: @text-heading-2-font-weight;
  color: @text-heading-2-color;
  margin-top: @text-heading-2-margin-top;
  margin-bottom: @text-heading-2-margin-bottom;

  @media @mobile {
    font-size: @text-heading-2-font-size-mobile;
  }
}
.text-heading-3 {
  font-size: @text-heading-3-font-size;
  line-height: @text-heading-3-line-height;
  font-family: @text-heading-3-font-family;
  font-weight: @text-heading-3-font-weight;
  font-weight: @text-heading-3-font-weight;
  color: @text-heading-3-color;
  margin-top: @text-heading-3-margin-top;
  margin-bottom: @text-heading-3-margin-bottom;

  @media @mobile {
    font-size: @text-heading-3-font-size-mobile;
  }
}

.text-heading-4 {
  font-size: @text-heading-4-font-size;
  line-height: @text-heading-4-line-height;
  font-family: @text-heading-4-font-family;
  font-weight: @text-heading-4-font-weight;
  font-weight: @text-heading-4-font-weight;
  color: @text-heading-4-color;
  margin-top: @text-heading-4-margin-top;
  margin-bottom: @text-heading-4-margin-bottom;

  @media @mobile {
    font-size: @text-heading-4-font-size-mobile;
  }
}

.text-paragraph {
  font-size: @text-paragraph-font-size;
  line-height: @text-paragraph-line-height;
  font-family: @text-paragraph-font-family;
  font-weight: @text-paragraph-font-weight;
  font-weight: @text-paragraph-font-weight;
  color: @text-paragraph-color;
  margin-top: @text-paragraph-margin-top;
  margin-bottom: @text-paragraph-margin-bottom;

  @media @mobile {
    font-size: @text-paragraph-font-size-mobile;
  }
}
